import * as React from 'react'
import emailIcon from '../images/emailIcon.svg'
import githubIcon from '../images/githubIcon.svg'

const Intro = ({ content }) => {
  return (
    <div className='mt-12 mx-12 md:mx-40 xl:mx-60 md:mt-24 md:max-w-2xl'>
      <div
        className='prose space-y-1 font-light'
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
      <div className='mt-16 flex '>
        <a
          href='https://github.com/ruanjianusa'
          target='_blank'
          rel='noreferrer'
        >
          <img className='mr-6 md:10' src={githubIcon} alt='icon-github' />
        </a>
        <a href='mailto:&#114;&#117;&#097;&#110;&#106;&#105;&#097;&#110;&#117;&#115;&#097;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;'>
          <img src={emailIcon} alt='icon-email' />
        </a>
      </div>
    </div>
  )
}
export default Intro
