import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import Intro from '../components/intro'

const IndexPage = ({ data }) => {
  const { html } = data.markdownRemark // data.markdownRemark holds your post data
  return (
    <Layout>
      <Intro content={html} />
    </Layout>
  )
}
export default IndexPage

export const query = graphql`
  query IntroQuery {
    markdownRemark(frontmatter: { type: { eq: "intro" } }) {
      html
    }
  }
`
